
.hot-keyword-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding:  0 20px;
    .search-content{
        margin-top: 20px;
    }
}

